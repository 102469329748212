@font-face {
  font-family: "SFPro";
  src: url("/public/fonts/SFPro/SFProText-Light.ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "SFPro";
  src: url("/public/fonts/SFPro/SFProText-Regular.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "SFPro";
  src: url("/public/fonts/SFPro/SFProText-Medium.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "SFPro";
  src: url("/public/fonts/SFPro/SFProText-Semibold.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SFPro";
  src: url("/public/fonts/SFPro/SFProText-Bold.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SFPro";
  src: url("/public/fonts/SFPro/SFProText-Heavy.ttf");
  font-weight: 700;
  font-style: normal;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.body {
  font-family: "SFPro", sans-serif;
  background: #000;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  overflow-x: hidden;
}

h1 {
  font-weight: 500;
  font-size: 48px;
}

.content_container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 10px;
  margin: 0 auto;
}

/* -------- */

.main_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  align-items: center;
  transition: 0.3s;
  width: 100%;
}
.main_container h1 {
  font-weight: 700;
}
.main_container h2 {
  font-weight: 500;
  font-size: 48px;
}
.main_container img {
  width: 100%;
}
.start_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slogan {
  padding: 35px;
  background: #121212;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.start_button {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 500px;
  background: #fff;
  color: #020202;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 30px;
  transition: 0.3s;
}
.description_container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;
  padding: 35px 50px;
  background: #121212;
  border-radius: 12px;
}
.description_container h3 {
  font-size: 20px;
  font-weight: 500;
  opacity: 0.25;
}

/* -------- */

.form_container {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}
.form_container input {
  padding: 20px 0;
  text-align: left;
  border: none;
  border-bottom: 1px solid #333;
  transition: border-color 0.3s;
  width: 100%;
}
.form_container input::placeholder {
  color: #333;
}
.form_container input:target,
.form_container input:focus {
  border-bottom: 1px solid #fff;
}
.form_container button {
  margin-top: none;
}
.reg_button {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.reg_button p {
  color: #333;
  font-size: 12px;
}
.reg_button p a {
  color: #fff;
}

/* -------- */

.select_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 12px;
  background: #121212;
  text-align: left;
  padding: 35px 50px;
  width: 500px;
}
.select_container li {
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  gap: 10px;
}
.select_container li img {
  height: 50px;
  width: 50px;
  transition: 0.3s;
}
.select_container li.disabled img {
  opacity: 0.25;
}
.select_container li span {
  width: 100%;
  flex-grow: 1;
  transition: 0.3s;
  padding-bottom: 15px;
}
.select_container li.disabled span {
  color: #333;
}
.select_container li:first-child {
  border-bottom: none;
  color: #333;
  cursor: default;
}
.select_container li + li span {
  border-bottom: 1px solid #333;
}
.select_container li:last-child span {
  border-bottom: none;
}

.start_button.disabled {
  opacity: 0.25;
  cursor: default;
}

.prepaire_anim {
  opacity: 0;
  transition: 1s;
}
.prepaire_anim.active {
  opacity: 1;
}

/* -------- */

.notify_container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  width: 1000px;
}
.notify_title {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  cursor: default;
  user-select: none;
  width: 1000px;
}
.notify_title span {
  opacity: 0.25;
}
.notify_buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
}
.notify_container .start_button {
  margin-top: 0;
}

.notify_container-block {
  color: #000;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.8);
  gap: 10px;
  max-width: 500px;
}
.notify_container-block .notify_title {
  padding: 10px;
  width: auto;
}
.notify_container-block .notify_title span {
  opacity: 1;
  color: #aaa;
  font-size: 18px;
  font-weight: 400;
}
.notify_container-block .notify_title h1 {
  font-weight: 700;
  font-size: 20px;
}
.notify_container-block .notify_title h2 {
  font-size: 18px;
  font-weight: 400;
}
.notify_container-block_buttons {
  display: flex;
  border-top: 1px solid #aaa;
}
.notify_container-block_button {
  padding: 10px;
  width: 100%;
  color: dodgerblue;
  font-weight: 700;
}

.notify_container-block_button + .notify_container-block_button {
  border-left: 1px solid #aaa;
}

/* -------- */

.slide-title {
  animation: slide-appearance 5s forwards ease-in-out;
}

@keyframes slide-appearance {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes simple-apperance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* -------- */

.event_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 500px;
}
.event_container .start_button {
  margin-top: none;
}
.event_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  text-align: left;
  width: 100%;
}
.event_info_item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.event_info_item span {
  opacity: 0.25;
}

/* -------- */

.admin_users {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  background: #121212;
  padding: 10px;
  border-radius: 12px;
  overflow-x: auto;
}
.admin_users li:first-child {
  color: #333;
  border-bottom: none;
}
.admin_users li {
  display: flex;
  gap: 5px;
  padding-bottom: 15px;
  font-size: 15px;
  align-items: center;
}
.admin_users li span {
  min-width: 150px;
  max-width: 150px;
}
.admin_users li + li {
  border-bottom: 1px solid #333;
}
.admin_users li:last-child {
  border-bottom: none;
  padding-bottom: none;
}

.delete_button {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 5px;
  color: #333;
  transition: 0.3s;
}
.delete_button:hover {
  color: #fff;
}

/* -------- */

@media (max-width: 1050px) {
  .main_container {
    max-width: unset;
  }
  .notify_container,
  .notify_title {
    width: 100%;
  }
}

@media (max-width: 550px) {
  h1 {
    font-size: 25px;
  }
  .main_container h2 {
    font-size: 25px;
  }

  .content_container {
    padding: 20px 10px;
  }
  .slogan,
  .description_container,
  .select_container {
    padding: 10px;
  }

  .description_container,
  .event_info,
  .notify_buttons,
  .select_container,
  .reg_button {
    gap: 20px;
    width: 100%;
  }
  .event_container,
  .form_container,
  .notify_container {
    gap: 30px;
    width: 100%;
  }

  .start_button {
    width: 100%;
  }

  .notify_container-block {
    margin: 0 35px;
  }
  .notify_container-block .notify_title span {
    font-size: 13px;
  }
  .notify_container-block .notify_title h1 {
    font-size: 16px;
  }
  .notify_container-block .notify_title h2 {
    font-size: 13px;
  }
  .notify_container-block_button {
    font-size: 16px;
  }
}
